@import '../../src/styling/fonts';

* {
  font-synthesis: none;
}
// Typography Variables
$base-font-size: 16px;
$base-line-height: 1.5;

// Heading Styles
$heading-font-family: 'basier_square_monoregular', sans-serif;
$heading-font-weight: bold;

$h1-font-size: $base-font-size * 2;
$h2-font-size: $base-font-size * 1.8;
$h3-font-size: $base-font-size * 1.6;
$h4-font-size: $base-font-size * 1.4;
$h5-font-size: $base-font-size * 1.2;
$h6-font-size: $base-font-size;

// Body Text Styles
$body-font-weight: normal;

$body-font-size: $base-font-size;
$body-line-height: $base-line-height;

@mixin stylistic-header-lg {
  font-feature-settings: 'ss02' on;
  font-family: 'chaney-extended', sans-serif;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: -0.68px;
}

@mixin stylistic-header-md {
  font-feature-settings: 'ss02' on;
  font-family: 'chaney-extended', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.48px;
}

@mixin stylistic-header-sm {
  font-family: 'basier_square_monoregular', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.48px;
  text-transform: uppercase;
}

@mixin header {
  font-family: 'basier_square', sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -2.4px;
}

@mixin headline-lg {
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -3.84px;
}

@mixin headline-sm {
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -2.88px;
}

@mixin subhead-lg {
  font-family: 'basier_square', sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.44px;
}

@mixin subhead-sm {
  font-family: 'basier_square', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.36px;
}

@mixin title-lg {
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: -1.12px;
}

@mixin title-sm {
  font-family: 'basier_square-500', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.48px;
}

@mixin subtitle-lg {
  font-family: 'basier_square-600', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.96px;
}

@mixin subtitle-md {
  font-family: 'basier_square-600', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.8px;
}

@mixin subtitle-sm {
  font-family: 'basier_square-600', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.64px;
}

@mixin subtitle-xs {
  font-family: 'basier_square-600', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -4%;
}

@mixin body-xl {
  font-family: 'basier_square', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.18px;
}

@mixin body-lg {
  font-family: 'basier_square', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.16px;
}

@mixin body-m {
  font-family: 'basier_square', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.28px;
}

@mixin body-s {
  font-family: 'basier_square', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

@mixin body-xs {
  font-family: 'basier_square', sans-serif;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.16px;
}

@mixin stylistic-body-lg {
  font-family: 'basier_square_monoregular', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.84px;
  text-transform: uppercase;
}

@mixin stylistic-body-md {
  font-family: 'basier_square_monoregular', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.72px;
  text-transform: uppercase;
}

@mixin stylistic-body-sm {
  font-family: 'basier_square_monoregular', sans-serif;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: -0.48px;
  text-transform: uppercase;
}

@mixin label-xl {
  font-family: 'basier_square-500', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.8px;
}

@mixin label-lg {
  font-family: 'basier_square-500', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.36px;
}

@mixin label-m {
  font-family: 'basier_square-500', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.32px;
}

@mixin label-s {
  font-family: 'basier_square-500', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.28px;
}

@mixin label-xs {
  font-family: 'basier_square-500', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.24px;
}

@mixin label-xxs {
  font-family: 'basier_square-500', sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: -2%;
}

@mixin link-lg {
  font-family: 'basier_square-700', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.32px;
}

@mixin link-md {
  font-family: 'basier_square-700', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.28px;
}

@mixin link-sm {
  font-family: 'basier_square-700', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: -0.24px;
}

@mixin fineprint {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: -0.24px;
  text-transform: uppercase;
}
