//Basier Square

@font-face {
  font-family: 'basier_square';
  src: url('../assets/fonts/Basier-Square-regular-webfont/basiersquare-regular-webfont.ttf')
    format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'basier_square-500';
  src: url('../assets/fonts/Basier-Square-regular-webfont/BasierSquare-Medium.otf')
    format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'basier_square-600';
  src: url('../assets/fonts/Basier-Square-regular-webfont/BasierSquare-SemiBold.otf')
    format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'basier_square-700';
  src: url('../assets/fonts/Basier-Square-bold-webfont/basiersquare-bold-webfont.ttf')
    format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

//Chaney

@font-face {
  font-family: 'chaney-regular';
  src: url('../assets/fonts/CHANEY-Regular-webfont/chaney-regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

//Chaney Extended

@font-face {
  font-family: 'chaney-extended';
  src: url('../assets/fonts/CHANEY-Extended-webfont/chaney-extended-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

//Baseier Square Narrow

@font-face {
  font-family: 'basier_square_narrow';
  src: url('../assets/fonts/Basier_Square_Narrow_webfontkit/basiersquarenarrow-regular-webfont.ttf')
    format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'basier_square_narrow-500';
  src: url('../assets/fonts/Basier_Square_Narrow_webfontkit/basiersquarenarrow-medium-webfont.ttf')
    format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'basier_square_narrow-600';
  src: url('../assets/fonts/Basier_Square_Narrow_webfontkit/basiersquarenarrow-semibold-webfont.ttf')
    format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'basier_square_narrow-700';
  src: url('../assets/fonts/Basier_Square_Narrow_webfontkit/basiersquarenarrow-bold-webfont.ttf')
    format('truetype');
  font-style: normal;
  font-display: swap;
}

//Basier Square Mono

@font-face {
  font-family: 'basier_square_monobold';
  src: url('../assets/fonts/Basier-Mono-Square-Webfont/basiersquaremono-bold-webfont.ttf')
    format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'basier_square_monobold_italic';
  src: url('../assets/fonts/Basier-Mono-Square-Webfont/basiersquaremono-bolditalic-webfont.ttf')
    format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'basier_square_monomedium';
  src: url('../assets/fonts/Basier-Mono-Square-Webfont/basiersquaremono-medium-webfont.ttf')
    format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'basier_square_monoMdIt';
  src: url('../assets/fonts/Basier-Mono-Square-Webfont/basiersquaremono-mediumitalic-webfont.ttf')
    format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'basier_square_monoregular';
  src: url('../assets/fonts/Basier-Mono-Square-Webfont/basiersquaremono-regular-webfont.ttf')
    format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'basier_square_monoRgIt';
  src: url('../assets/fonts/Basier-Mono-Square-Webfont/basiersquaremono-regularitalic-webfont.ttf')
    format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'basier_square_monosemibold';
  src: url('../assets/fonts/Basier-Mono-Square-Webfont/basiersquaremono-semibold-webfont.ttf')
    format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'basier_square_monoSBdIt';
  src: url('../assets/fonts/Basier-Mono-Square-Webfont/basiersquaremono-semibolditalic-webfont.ttf')
    format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
