/* You can add global styles to this file, and also import other style files */

@import '../src/styling/variables';
@import '../src/styling/fonts';
@import '../src/styling/typography';

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

html,
body {
  background: var(--Utility-10, #1a141a);
  padding: 0;
  margin: 0;
  font-family: 'basier_square', sans-serif;
  color: $white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  color: $white;
}

p,
a,
ul,
li {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.flex {
  display: flex;
}

.link {
  cursor: pointer;
}

.h-full {
  height: 100%;
}

.overlay {
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: fixed; /* Stay in place */
  z-index: 999999; /* Sit on top */
  left: 0;
  top: 0;
  background-color: transparent;
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}

//////Customization of Bootstrap Modal /////

.modal {
  z-index: 99999;
}

.modal-content {
  font-family: 'basier_square_monoregular', sans-serif;
  background-color: transparent;
  z-index: 1050;
}

.modal-dialog {
  transition: 1s ease-in-out;
  max-width: none !important;
}

.fade {
  transition: opacity 0s ease-in;
}

.modal-content {
  border: none;
}

.modal.fade {
  opacity: 1;
}

.modal.fade .modal-dialog,
.modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: 0s;
}

ngb-modal-window {
  overflow: hidden !important;
}

ngb-modal-window > .modal-dialog {
  width: fit-content;
  height: 100%;
  margin: 40px auto;
  display: flex;
  align-items: start;
  padding-bottom: 40px;
}

@media (min-width: $s) {
  ngb-modal-window > .modal-dialog {
    margin: auto;
    display: flex;
    align-items: center;
  }
}

ngb-modal-window {
  width: 100%;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  align-content: center;
  justify-content: center;
  background: none;
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s;
  font-family: 'basier_square-700', sans-serif;
}

.modal-content {
  border-radius: 25px;
}

/// Scrollbar///

::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f6f6f6;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #adadad;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.flex-column {
  flex-direction: column;
}

.pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.w-full {
  width: 100%;
}

.w-fit-content {
  width: fit-content;
}

.items-center {
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.form-check-input {
  margin-right: 15px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid $utility-80;
  border-radius: 2px;
  position: relative;
  cursor: pointer;
  accent-color: transparent;
  background-color: transparent;
}

.form-check-input:focus {
  border-color: unset;
  outline: 0;
  box-shadow: unset;
}

.form-check-input[type='checkbox'] {
  background-color: transparent;
  border-radius: 2px;
  border: 2px solid $utility-80;
  margin: 0;
}

.form-check-input:checked[type='checkbox'] {
  color: black;
  background-color: #ddc9de;
  border-radius: 2px;
  border: none;
  outline: 0;
}

.form-check-input:checked {
  background-color: #1a141a;
  border-color: #1a141a;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black"><path d="M20.285 4.285L9 15.57l-5.285-5.285-2.143 2.143 7.428 7.428 13.428-13.428z"/></svg>');
  background-size: 14px;
  background-position: center;
  background-repeat: no-repeat;
}

.form-check-input:hover[type='checkbox'] {
  background-color: $utility-70;
  border: 2px solid $utility-70;
  background-size: 14px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black"><path d="M20.285 4.285L9 15.57l-5.285-5.285-2.143 2.143 7.428 7.428 13.428-13.428z"/></svg>');
}

.carousel-indicators {
  bottom: unset;
  justify-content: start;
  padding: 0;
  margin: 8px 0 10px;
  position: unset;
  border-top: unset;
  border-bottom: unset;

  & > button {
    background: rgba(255, 250, 255, 0.2);
    backdrop-filter: blur(10px);
    border-top: unset;
    border-bottom: unset;
  }

  & .active {
    color: #ffe2ff;
    background-color: #ffe2ff;
  }
}

stxt-conversation-page {
  width: 100%;
}

//// Customization of Bootstrap Nav

.nav-link {
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  text-decoration: none;
  background: none;
  border: 0;
  color: var(--Utility-70, #bba7ba);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.36px;
  transition: color 0.15s ease-in-out;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--Utility-80, #ddc9de);
  background-color: unset;
  border-color: unset;
}

.nav-tabs .nav-link {
  border: unset;
  border-top-left-radius: unset;
  border-top-right-radius: unset;
}

.btn-nostyle {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;

  &:focus {
    outline: none;
  }
}

.image-inherit {
  position: inherit !important;
}

.opacity-0 {
  opacity: 0;
}

.highlight {
  color: var(--Utility-90, #ffe2ff);
}

.fixed-toast {
  position: fixed;
  bottom: 20px;
  z-index: 1;
  right: 20px;
  width: 93%;
}

@media (min-width: $md) {
  .fixed-toast {
    position: fixed;
    bottom: 20px;
    z-index: 1;
    width: 435px;
  }
}

.custom-tooltip .tooltip-inner {
  @include body-s;
  background-color: $utility-30;
  border: 1px solid $utility-40;
  color: $utility-70;
  display: inline-flex;
  padding: 2px 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  margin: 15px 0 0 15px;
}

.custom-tooltip.bs-tooltip-end .tooltip-arrow::before,
.custom-tooltip.bs-tooltip-start .tooltip-arrow::before,
.custom-tooltip.bs-tooltip-top .tooltip-arrow::before,
.custom-tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  display: none;
}
