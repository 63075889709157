//// Brand Colors ////
$primary: #ff6af1;
$primary-2: #de32ce;
$primary-3: #af00a2;
$primary-4: #7b0072;
$primary-5: #490143;
$primary-6: #270624;

$secondary: #ffb4fb;
$secondary-2: #ffe2ff;
$secondary-3: #fffaff;

$brand-darker: #ff6af133;

$error: #ff8d80;

//// Colors ////

$white: #ffffff;
$athens-gray: #f9f8fa;
$grey-light: #d8d6db;
$french-gray: #b9b6bd;
$grey-mountain-mist: #9a969f;
$ship-gray: #434147;
$grey-mine-shaft: #2a282c;
$grey: rgba(0, 0, 0, 0.48);
$black: #000000;
$black-diesel: #050004;

$success-70: #7acf7a;
$success-80: #b1e9b0;
$warning-50: #a16f00;
$warning-70: #e5ab55;
$warning-80: #f8d09a;
$danger-30: #830004;
$danger-50: #df2124;
$danger-70: #fd574d;
$info-80: #b8daff;

//// Utility Colors ////

$utility-10: #1a141a;
$utility-20: #231d23;
$utility-30: #2a232a;
$utility-40: #322b32;
$utility-50: #6f5c6e;
$utility-60: #8c768e;
$utility-70: #bba7ba;
$utility-80: #ddc9de;
$utility-90: #ffe2ff;

///// Breakpoints ////

$xs: 320px;
$s: 640px;
$md: 926px;
$lg: 1280px;
$xl: 1440px;
$xxl: 1440px;

:root {
  --Utility-0: #030103;
  --Utility-10: #1a141a;
  --Utility-20: #231d23;
  --Utility-30: #2a232a;
  --Utility-40: #2a232a;
  --Utility-50: #6f5c6e;
  --Utility-60: #8c768e;
  --Utility-70: #bba7ba;
  --Utility-80: #ddc9de;
  --Utility-90: #ffe2ff;
  --Danger-70: #ff8d80;
}
